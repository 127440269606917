.c-content-table{
  margin-bottom: 3.1rem;
    background: rgb(23, 105, 255);
    border-radius: 0.8rem;
    padding: 1.5rem 1.5rem 3rem;
    &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
     &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &__list[class]{
    list-style: auto;
    padding: 1.5rem 0rem 0rem 3.5rem;
  }
  &__title{
    color: white;
    display: block;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    text-shadow: rgba(9, 10, 11, 0.5) 0px 0.8rem 0.8rem;
  }
  &__list-item{
    color: white;
    text-shadow: rgba(9, 10, 11, 0.5) 0px 0.8rem 0.8rem;
  }
  &__list-item + &__list-item{
    margin-top: 1.2rem;
    
  }
  &__list-link{
    margin-left: 1rem;
    color: white;
    text-shadow: rgba(9, 10, 11, 0.5) 0px 0.8rem 0.8rem;
    &:hover{
        text-decoration: underline;
    }
    
  }
}


@media(min-width:555px){
  .c-content-table{
    &__list{
      column-count: 2;
      column-gap: 5.2rem;
    }
  }
}


@media(min-width:992px){
  .c-content-table{
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: 0.8rem;
    display: block;
    top: 9rem;
    right: 0.8rem;
    position: fixed;
    z-index: 2;
    width: 30rem;
     height: 100vh;
    overflow-y: auto;
    padding: 2rem 1rem;
  
   &__list[class]{
      column-count: 1;
      
    }
  }
}



