.c-faq{
  margin-bottom: 5rem;
  details {
    margin-bottom: 1.7rem;
}
  summary {
    color: white;
    padding: 1.3rem 1.9rem;
    padding-right: 5.6rem;
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    position: relative;
    outline: none;
    background: #1769ff;
     border-radius: 0.8rem;
     font-weight: 500;
 }
  summary:before {
    content: "";
    display: block;
    height: 3.1rem;
    width: 3.1rem; 
    position: absolute;
    background-image: url('../img/arrow.svg');
    transition: transform .3s linear;
    top:50%;
    right: 1.5rem;
    transform: translateY(-50%);
   
}
  details[open] summary:before {
    transform: rotate(180deg) translateY(50%);
     
}
  details > div {
    padding: 1.6rem;
}
  }
