.c-aside {
    z-index: 5;
    background: white;
    transition: transform .3s linear;
    width: 100%;
    top: 0;
    left: 0;
    transform: translateX(-140%);
    height: 100vh;
    position: fixed;
    max-width: 27.2rem;
    border-bottom-right-radius: .8rem;
    border-top-right-radius: .8rem;
}

.c-menu {
    padding-left: 2rem;
    padding-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    }
    &::-webkit-scrollbar-thumb {
    background: transparent;
    }
    &::-webkit-scrollbar-track {
    background: transparent;
    }
    &__close{
        height: 24px;
        top: 2rem;
    right: -5rem;
    width: 24px;
    position: absolute;
    }
    &__banner{
        margin-right: 1rem;
        border-bottom-right-radius: 3rem;
        position: relative;
        padding: 2.4rem 2rem;
    }
    &__banner-img{
        position: absolute;
        border-bottom-right-radius: 3rem;
         top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &__banner-info{
        position: relative;
    }
    &__banner-title{
        font-size: 2.4rem;
        font-weight: bold;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        line-height: 1.2;
        color: #1b204f;
    }
    &__banner-text{
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        font-size: 1.4rem;
        line-height: 1.8;
        font-weight: 500;
        color: #1b204f;
    }
    &__banner-bonus{
        color: #1b204f;
         overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        font-weight: bold;
        margin-bottom: 3rem;
    }
    &__banner-links{
        display: flex;
        align-items: center;
    }
    &__banner-log{
        font-size: 1.3rem;
        color: white;
        background: #1769ff;
        display: block;
        border-radius: .8rem;
        text-align: center;
        font-weight: bold;
        margin-right: 1.2rem;
        text-transform: uppercase;
        padding: 0.8rem 1.45rem;
        transition: background .3s linear;
        &:hover{
            background: #1790ff;
        }
    }
    &__banner-sign{
        border: .2rem solid #1769ff;
        color: #1769ff;
        font-weight: bold;
        border-radius: .8rem;
        display: block;
        font-size: 1.3rem;
        text-align: center;
        text-transform: uppercase;
        padding: 0.65rem 1.45rem;
        transition: background .3s linear, color .3s linear;
        &:hover{
            background: #1769ff;
            color: white;
        }

    }
    &__list-link{
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8;
        color: #212121;
        height: 4.6rem;
        svg{
            margin-right: 0.8rem;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    &__list-item{
        border-bottom: .2rem solid transparent;
        border-image: linear-gradient(90deg,#eee,#fff) 1;
    }


    
}


@media (min-width:375px) {
    .c-aside {
        max-width: 32.7rem;
    }
}


.c-aside.is-active {
    transform: translateX(0);
}




@media (min-width:1400px) {
    .c-aside {
        max-width: none;
        transform: translateX(0);
        background: transparent;
        width: auto;
        height: auto;
        padding: 0;
        position: static;
        overflow: visible;
    }
    .c-menu{
        height: 8rem;
        flex-direction: row;
        padding: 0;
        margin-bottom: 0;
        margin-left: 6.4rem;
        &__banner{
            display: none;
        }
        &__close{
            display: none;
        }
        &__list{
            display: flex;
            align-items: center;
            column-gap: 1.4rem;
        }
        &__list-item{
            border-bottom: none;
            height: 100%;
        }
        &__list-link{
            height: 100%;
            white-space: nowrap;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            border-bottom: 4px transparent solid;
            svg{
                display: none;
            }
            &:hover{
                text-decoration: none;
                color: #1769ff;
             border-color: #1769ff;
            }
           
        }
        &__list-link--active{
             color: #1769ff;
             border-bottom: 4px #1769ff solid;
        }
       
    }
    .c-aside{
        margin-right: auto;
    }
}




