*,*::before,*::after {
  box-sizing: border-box;
}
ul[class],ol[class] {
  margin: 0;
  padding: 0;
}
body,h1,h2,h3,h4,p,li,figure,figcaption,blockquote,dl,dd {
  margin: 0;
}
html{
  font-size: 10px;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
section > * + *, article > * + * {
  margin-top: 1em;
}
input,button,textarea,select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


.o-center{
  display: block;
  text-align: center;
}




.o-btn{
  background: transparent;
  border: none;
  padding: 0;
}



a,button{
  cursor: pointer;
}

a:not([class]){
  color: #1769ff;
  &:hover{
    text-decoration: underline;
  }
}


body{
  font-family: 'Inter',sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #212121;
  background: #fafafa;
}

h1, h2, h3, h4, h5, h6{
  font-weight: bold;
}

li:not([class]){
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h1{
  font-size: 2.4rem;
}

h2{
  font-size: 2.2rem;
}

h3{
  font-size: 2.0rem;
}

h4{
  font-size: 1.8rem;
}

a{
  text-decoration: none;
}



.o-table-wrap{
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
     &::-webkit-scrollbar-thumb {
    background: #1769ff;
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

body.no-scroll{
  overflow: hidden;
}



.c-main{
  width: 100%;
  padding-top: 5.6rem;
}


@media(min-width: 768px){
  .c-main{
    padding-top: 8rem;
  }
}



.o-title{
  font-weight: bold;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  &__decor{
    margin-right: 1.2rem;
  }
  &__line{
    display: none;
  }
  span{
    flex-shrink: 0;
  }
}



@media(min-width: 992px){
  .o-title{
  overflow: hidden;
  &__line{
    display: block;
    margin-left: 2rem;
    width: 100%;
    height: 0.8px;
    background: #eee;
  }
  }
}


table{
  border-collapse: collapse;
  margin: 1.7rem auto;
  overflow: hidden;
  text-align: center;
}

thead{
  background: #1769ff;
  color: #fff;
}

tr{
  margin: 0;
}


th,td{
  padding: 1.2rem;
  min-width: 12rem;
  border: 0.1rem solid #1769ff;
}



@media (min-width: 768px){
  h1{
  font-size: 3.2rem;
}

h2{
  font-size: 2.8rem;
}

h3{
  font-size: 2.4rem;
}

h4{
  font-size: 2rem;
}
}

.o-wrap{
      max-width: 1340px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}




@media(min-width: 992px){
  body{
   padding-right: 310px;
  }
}


.o-embed {
  margin: 1.5rem auto;
  position: relative;
  overflow: hidden;
  max-width: 760px;
}

.o-embed--mod {
  max-width: 100%;
  margin-top: 0;
}

.o-embed::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.o-embed iframe,
.o-embed video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1000px;
  border: none;
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}


@media (min-width: 768px) {
.o-embed {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  max-width: 670px;
}

.o-embed--iframe {
  max-width: none;
}

.o-embed--900 {
  max-width: 900px;
}

.o-embed::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.o-embed iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1000px;
  border: none;
}
}



.o-img-center{
  display: block;
  margin: 2.1rem auto;
}


.svg-icon {
    display: block;
    height: 2.4rem;
    width: 2.4rem;
}


.c-float{
  &__right{
    float: right;
    margin-left:1.2rem;
    margin-top:0.6rem;
    margin-bottom: 0.6rem;
  }
  &__left{
    float: left;
    margin-right: 1.2rem;
    margin-top: 0.6;
    margin-bottom: 0.6rem;
  }
}



.o-clearfix::after{
  content:'';
  display:block;
  clear:both;
}

.hidden{
  display: none;
}