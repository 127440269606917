
.c-headers {
    width: 100%;
    z-index: 3;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 1.8rem;
    background: #fff;
    height: 5.6rem;
    transition: padding-left .2s linear;
    &__btn-menu{
        display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 1.8rem;
    height: 2.4rem;
    width: 2.4rem;
    z-index: 1;
    }
    &__decor{
        position: absolute;
        left: -5.8rem;
        bottom: 0;
        width: 17.6rem;
    }
    &__gradient{
        position: absolute;
        background: linear-gradient(135deg,rgba(255,23,134,.24) 33.76%,#1790ff 50.76%,#17f1ff 63.71%);
        filter: blur(3.2rem);
        width: 13.7rem;
        transform: matrix(-1,0,0,1,0,0);
        opacity: .46;
        right: 0;
        height: 5.6rem;
    }
    &__btn{
        position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.4rem 1.1rem;
    background: #fff;
    }
    &__btn-log{
    padding: 1rem 1.5rem;
    border-radius: 8px;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    color: #1769ff;
    text-align: center;
    &:hover{
       text-decoration: underline;
    }
    }
    &__btn-sign {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
    padding: 1rem 3.4rem;
    border-radius: 8px;
    margin-left: 1.6rem;
    color: #fff;
    background: #1769ff;
    position: relative;
    text-align: center;
    transition: background .3s linear;
    &:hover{
        background: #1790ff;
    }
}
}





@media (min-width:555px) {
    .c-headers {
        &__btn {
        background: transparent;
        width: auto;
        position: relative;
        padding: 0;
        margin-left: auto;
       }
       &__btn-log {
        padding: 0.8rem;
        font-size: 1.3rem;
      }
      &__btn-sign {
        font-size: 1.3rem;
        padding: 0.8rem 1.4rem;
        margin-left: 0.8rem;
    }
    }

}



@media (min-width:768px) {
    .c-headers {
        height: 8rem;
        padding-left: 2rem;
        padding-right: 2rem;
        &__decor{
            left: -6.4rem;
        }
        &__logo-icon{
            img{
                width: 84px;
                height: 48px;
            }
        }
      &__btn-sign {
        padding: 1.2rem 2.4rem;
        margin-left: 1rem;
    }
    }
}


@media (min-width:1400px) {
    .c-headers {
        &__btn-menu{
            display: none;
        }
    }
}


.c-headers-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(23,105,255,.8);
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}

.c-headers-overlay.is-active {
    opacity: 1;
    visibility: visible;
}

