.c-games{
  &__items[class]{
    margin-bottom: 2.8rem;
  }
  &__items{
    column-gap: 0.8rem;
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    width: calc(100% / 2 - 0.4rem);
    margin-bottom: 0.8rem;
    overflow: hidden;
    &:hover{
      cursor: pointer;
      .c-games__item-overlay{
        opacity: 1;
      }
    }
  }
  &__item-name{
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding-left: 0.2rem;
     padding-right: 0.2rem;
    overflow: hidden;
    font-size: 1.3rem;
    font-weight: 500;
  }


  &__img{
    position: relative;
    overflow: hidden;
    border-radius: 0.8rem;
    margin-bottom: 0.4rem;
  }
  &__img img{
    width: 100%;
    height: auto; 
  }
  &__item-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.6rem;
      opacity: 0;
      transition: opacity .3s linear;
      display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    background: linear-gradient(rgba(19, 60, 0, 0), #04271e);
    }
    &__btns{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__play{
      display: block;
      cursor: pointer;
    }
    &__demo{
     font-size: 1.4rem;
     font-weight: bold;
     color: white;
     &:hover{
      text-decoration: underline;
     }
    }
}




@media(min-width: 450px){
  .c-games{
    &__item{
      width: calc(100% / 3 - 0.6rem);
    }
  }
}





@media(min-width: 650px){
  .c-games{
     &__item{
      width: calc(100% / 4 - 0.6rem);
    }
  }
}




@media(min-width: 768px){
  .c-games{
     &__item{
      width: calc(100% / 5 - 0.7rem);
    }
  }
}


@media(min-width: 1400px){
  .c-games{
     &__item{
      width: calc(100% / 6 - 0.7rem);
    }
    &__play{
        margin-bottom: 1rem;
    }
  }
}





