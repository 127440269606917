.c-comments{
    margin-top: 2rem;
    margin-bottom: 5.5rem;
    &__item {
        position: relative;
        padding: 1.9rem 1.9rem 4.3rem;
        background: #fff;
        box-shadow: 0 .8rem 3.2rem rgba(9,10,11,.05);
        border-radius: 0.8rem;
        width: 100%;
    & + .c-comments__item{
        margin-top: 4.5rem;
    }
}
    &__info{
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
    }
    &__name{
        margin-right: 1.2rem;
        font-weight: bold;
    }
    &__star{
    display: flex;
    height: 1.6rem;
    width: 8.9rem;
}
    &__star-color {
    height: 1.6rem;
    overflow-x: hidden;
    display: flex;
}
    &__btn{
        position: absolute;
       right: 5%;  
       bottom: -2rem;
        padding: 1rem 3rem;
        font-size: 1.4rem;
    }
}








