.c-heroes{
  margin-bottom: 3.1rem;
  height: 320px;
  position: relative;
  overflow: hidden;
  padding: 3.2rem 1.8rem 2rem 1.8rem;
  &__img{
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
    top: 0;
    left: 0;
    object-position: center;
  }
  &__left{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &__right{
     position: absolute;
    right: 0;
    bottom: 0;
  }
  &__info{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  }
  &__title{
    font-weight: bold;
    display: block;
    font-size: 2.4rem;
    color: white;
    text-align: center;
    text-shadow: 0 .8rem .8rem rgba(9,10,11,.5);
  }
  &__text{
    text-align: center;
    text-shadow: 0 .8rem .8rem rgba(9,10,11,.5);
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2.3rem;
  }
  &__link{
    color: #1769ff;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 1.2rem 2rem;
    border-radius: .8rem;
    display: block;
    text-align: center;
    text-transform: uppercase;
    background: #e3f8ff;
    &:hover{
      box-shadow: 0 .8rem .8rem rgba(9,10,11,.05);
      background: white;
    }
  }
  &__list{
    display: none;
  }
}




@media(min-width: 768px){
  .c-heroes{
    padding-left: 8rem;
    padding-right: 8rem;
  }
}


@media(min-width: 992px){
  .c-heroes{
    margin-right: -310px;
    &__right{
      right: 30.3rem;
    }
    &__info{
      padding-right: 28.3rem;
    }
  }
}


@media(min-width: 1200px){
  .c-heroes{
    height: 508px;
    &__title{
     font-size: 4.8rem;
    }
    &__text{
     font-size: 4.8rem;
     margin-bottom: 3.2rem;
    }
    &__link{
      font-size: 1.6rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5.6rem;
      padding-right: 5.4rem;
      margin-bottom: 6rem;
    }
    &__left{
      height: 100%;
      width: auto;
      left: auto;
      right: 67%;
    }
    &__right{
      height: 100%;
      width: auto;
      right: 26rem;
    }
  }
}


@media(min-width: 1400px){
  .c-heroes{
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    &__list[class]{
      margin-top: auto;
    }
 &__list{
      display: flex;
      position: relative;
      align-items: center;
      column-gap: 3.2rem;
    }
    &__list-item{
      display: flex;
      align-items: center;
      background: linear-gradient(90deg,hsla(0,0%,100%,.16),hsla(0,0%,100%,.16)) padding-box,linear-gradient(180deg,hsla(0,0%,100%,.16),transparent) border-box;
      backdrop-filter: blur(1.2rem);
      border-radius: .8rem;
      padding: 1rem 1.6rem 1rem 3.5rem;
      position: relative;
      img{
        position: absolute;
        margin-right: 1.2rem;
        left: -23px;
      }
    }
    &__title{
      margin-top: auto;
    }
    &__item-title{
      font-size: 2rem;
      font-weight: bold;
      line-height: 2.4;
      display: block;
      color: white;
      line-height: 1.4;
    }
    &__span{
      line-height: 1.3;
      color: white;
      font-size: 1.4rem;
    }
    &__list-info{
      display: flex;
      flex-direction: column;
    }
    &__link{
      margin-bottom: 2rem;
    }
  }
}


.c-categories{
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
   overflow-x: auto;
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    }
    &::-webkit-scrollbar-thumb {
    background: #8cddf7;
    border-radius: 11rem;
    }
    &::-webkit-scrollbar-track {
    background: transparent;
    }
  
  &__link{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212121;
    font-weight: 500;
    border: .2rem solid #6b9fff;
    border-radius: 0.8rem;
    flex-shrink: 0;
    white-space: nowrap;
    padding: 0.6rem 1.2rem 0.6rem 0.6rem;
    font-size: 1.4rem;
    transition: background .3s linear;
    height: 4rem;
    svg{
      margin-right: 0.8rem;
      display: block;
    }
    &:hover{
    background: #fff;
    box-shadow: 0 .8rem 3.2rem rgba(9,10,11,.05);
    color: #1769ff;
  }
  }
  &__list[class]{
    padding-bottom: 1rem;
    
  }

  &__list{
    display: flex;
  align-items: center;
  position: relative;
  column-gap: 1.6rem;
  }
  &__list-item{
    display: flex;
    flex-shrink: 0;
  }
  &__list-link{
    padding-left: 0.6rem;
    padding-right: 1.2rem;
    height: 4rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: #212121;
    flex-shrink: 0;
    transition: color .3s linear;
    border-radius: .8rem;
    img, svg{
      margin-right: 0.8rem;
    }
    &:hover{
      box-shadow: 0 .8rem 3.2rem rgba(9,10,11,.05);
      color: #1769ff;
      background: white;
    }
  }
}


