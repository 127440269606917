@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Regular.woff") format("woff"), 
    url("../fonts/Inter-Regular.woff2") format("woff2"); 
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Bold.woff") format("woff"), 
    url("../fonts/Inter-Bold.woff2") format("woff2"); 
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url("../fonts/Inter-Medium.woff") format("woff"), 
    url("../fonts/Inter-Medium.woff2") format("woff2"); 
  font-weight: 500;
  font-display: swap;
}
