.c-footers{
  padding-top: 5rem;
  background: #181a2c;
  &__nav{
    padding-bottom: 3rem;
    justify-content: center;
  }
  &__nav-title{
    color: white;
    font-weight: bold;
    border-bottom: .1rem solid hsla(0,0%,100%,.08);
    line-height: 2.8rem;
    font-size: 2rem;
    display: block;
    margin-bottom: 2.4rem;
    padding-bottom: 1.6rem;
    text-align: center;
    white-space: nowrap;
  }
  &__nav-list{
    column-count: 2;
    column-gap: 4rem;
  }
  &__nav-link{
    font-size: 1.4rem;
    display: block;
    color: hsla(0,0%,100%,.48);
    transition: color .3s linear;
    line-height: 2.4rem;
    &:hover{
      color: #fff;
    }

  }
  &__nav-item + &__nav-item{
    margin-top: 0.5rem;
  }
  &__nav-items{
    margin-bottom: 3rem;
  }
  
  &__socials-item + &__socials-item{
    margin-top: 1.6rem;
  }
  &__socials-link{
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    img, svg{
      margin-right: 0.6rem;
    }
  }
  &__wrap{
    background: #0e0f19;
    padding-top: 5rem;
    border-top: 1px solid #3c3d45;
  }
  &__list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__list[class]{
    margin-bottom: 3.2rem;
  }
  &__list-item{
    margin-bottom: 1.2rem;
  }
  &__list-payments{
    justify-content: center;
    background: #181a2c;
    border-radius: .8rem;
  }
  &__list-payments[class]{
     padding: 1.2rem 1.2rem 0;
  }
  &__license-list{
    justify-content: center;
  }
  &__license-item{
    padding-bottom: 1.2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  &__responsibility{
    width: 100%;
    max-width: 608px;
    margin: 4.5rem auto 4rem;
    display: block;
  }
  &__text{
    font-size: 1.2rem;
    color: hsla(0,0%,100%,.24);
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__copy{
    color: hsla(0,0%,100%,.24);
    font-size: 1.2rem;
    text-align: center;
    display: block;
    padding-bottom: 10.5rem;
  }











}







@media (min-width: 555px){
  .c-footers{
    &__nav{
      display: flex;
      flex-wrap: wrap;
      column-gap: 5.2rem;
    }
    &__nav-title{
      text-align: left;
    }
    &__nav-items{
      max-width: 270px;
    }
    &__copy{
      padding-bottom: 5rem;
    }
    
  }
}


@media (min-width: 650px){
  .c-footers{
    &__nav{
      column-gap: 8rem;
    }
    &__nav-items{
      max-width: 320px;
    }
    &__wrap{
      padding-top: 7.5rem;
    }
  }
}




@media (min-width: 1200px){
  .c-footers{
    &__nav-column{
      column-count: 1;
      max-width: 140px;
    }
    &__list{
      justify-content: space-between;
    }
    &__license-list{
      justify-content: center;
    }
    &__list-payments{
      justify-content: center;
    }
  }
}


@media (min-width: 1400px){
  .c-footers{
    &__nav{
      flex-wrap: nowrap;
      justify-content: space-between;
      column-gap: 5rem;
    }
    &__nav-items{
      max-width: 380px;
      .c-nav__column{
        max-width: 140px;
      }
    }
  }
}